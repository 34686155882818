import React, { useState } from "react";
import _ from "lodash";
import {
  ShoppingOutlined,
  MenuOutlined,
  UserOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { IRoute } from "../../model/mainRoute";
function Navbar() {
  const routesMap: IRoute[] = [
    {
      id: 1,
      name: "HOME",
      path: "/",
    },
    {
      id: 2,
      name: "COLLECTION",
      path: "/collection",
    },
    {
      id: 3,
      name: "CONTACT",
      path: "/contact",
    },
    {
      id: 4,
      name: "ABOUT",
      path: "/about",
    },
  ];
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <nav className="bg-white shadow-md sticky top-0  w-full z-10 px-2 md:px-36">
      <div className="container mx-auto px-4 flex justify-between items-center py-4">
        {/* Left Icon */}
        <div className="flex items-center">
          <MenuOutlined
            className="text-2xl md:hidden cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          />

          <div className="text-2xl font-bold ml-4 md:ml-0">
            <Link to="/">BIJO</Link>
          </div>
        </div>

        {/* Middle Links */}
        <div className="hidden md:flex space-x-8">
          {_.map(routesMap, (item) => (
            <Link
              to={item.path}
              className="hover:text-blue-600 font-semibold"
              key={item.id}
            >
              {item.name}
            </Link>
          ))}
        </div>

        {/* Right Icons */}
        <div className="flex items-center space-x-4 md:space-x-12">
          <UserOutlined className="text-xl cursor-pointer" />
          <ShoppingOutlined className="text-xl cursor-pointer" />
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-white w-full  ">
          <div className="container mx-auto px-4 py-4 flex justify-between items-center">
            <CloseOutlined
              className="text-2xl cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="flex flex-col items-center space-y-4 py-4">
            {_.map(routesMap, (item) => (
              <Link
                to={item.path}
                className="hover:text-blue-600 font-semibold"
                key={item.id}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
