import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import Navbar from "./components/navbar";

const HomePage = lazy(() => import("./pages/home"));
const CollectionPage = lazy(() => import("./pages/collection"));
const ContactPage = lazy(() => import("./pages/contact"));
const AboutPage = lazy(() => import("./pages/about"));
const LoginPage = lazy(() => import("./pages/login"));
const RegisterPage = lazy(() => import("./pages/regsiter"));
const ProductPage = lazy(() => import("./pages/product"));
export default function App() {
  const Fallback = () => (
    <div className="flex justify-center items-center h-screen">
      <PulseLoader color="#008cff" />
    </div>
  );
  return (
    <Suspense fallback={<Fallback />}>
      <Navbar />
      <div className="md:px-36">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/collection" element={<CollectionPage />} />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/Contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
        </Routes>
      </div>
    </Suspense>
  );
}
